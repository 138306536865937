@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

:root {
  --primary: #0063D3 !important;
  --green: #26B170 !important;
  --darkblue: #093467 !important;
  --yellow: #ffb55a !important;
  --lightyellow: #FFDE8B !important;
  --lightblue: #E0EFFF !important;
  --black: #151515 !important;
  --primary-font: 'Poppins' !important;
  --secondary-font: 'Poppins' !important;
  --nt-btn-bg: #0063D3 !important;
  --nt-btn-color: #fff;
  --nt-btn-border-color: #0063D3;
  --nt-btn-hover-bg: #BAE4F6;
  --nt-btn-hover-color: #000;
}

* {
  font-family: var(--primary-font);
}

h1 {
  color: var(--black) !important;
  font-weight: 800 !important;
  font-size: 42px !important;
  line-height: 1.5;
}

h2 {
  color: var(--black) !important;
  font-weight: 600 !important;
  font-size: 20px !important;
}

h3 {
  color: var(--black) !important;
  font-weight: 600 !important;
  font-size: 28px !important;

}

h4 {
  color: var(--black) !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 1.5;
}

h5 {
  color: var(--primary) !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}

h6 {
  color: var(--black) !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

p {
  line-height: 1.8 !important;
  font-weight: 500 !important;
  color: var(--black) !important;
  font-size: 15px !important;
}

a {
  text-decoration: inherit !important;
  color: var(--primary) !important;
}

a:hover {
  color: #26b170 !important;
}

subtittle1 {
  color: var(--black) !important;
  font-weight: 800 !important;
  font-size: 38px !important;
}

subtittle2 {
  color: var(--black) !important;
  font-weight: 600 !important;
  font-size: 36px !important;
}

span1 {
  font-weight: 500 !important;
  font-size: 18px !important;
}

f22 {
  font-size: 22px !important;
  font-weight: 600 !important;
}

f22w500 {
  font-size: 22px !important;
  font-weight: 500 !important;
}

f14 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

f18w600 {
  font-weight: 600 !important;
  font-size: 18px !important;
}

f20w600 {
  font-weight: 600 !important;
  font-size: 18px !important;
}

f15w500 {
  font-size: 15px !important;
  font-weight: 500 !important;
}

f16w600 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

/* ====================
         Button
  =====================*/
.btn-web {
  background-color: var(--nt-btn-bg) !important;
  border-color: var(--nt-btn-border-color) !important;
  color: var(--nt-btn-color) !important;
  box-shadow: var(--nt-btn-shadow);
  font-family: var(--primary-font) !important;
  font-size: 18px;
  border-radius: 14px !important;
  padding: 8px 30px !important;
}

.btn-web:hover {
  background-color: var(--nt-btn-hover-bg) !important;
  color: var(--nt-btn-hover-color) !important;
}

/* ====================
         Button-blue
  =====================*/




/* Bounce To Right */

.btn_book {
  background: url(../images/home/book_but.png) no-repeat top center !important;
  width: 280px !important;
  height: 40px !important;
  background-size: 100% 100% !important;
  line-height: 1.4 !important;
}

.btn_book:before {
  background: url(../images/home/book_hvr.png) no-repeat top center !important;
  background-size: 100% 100% !important;
}

/****************/

.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0); */
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
  background: url(../images/home/but.png) no-repeat top center;
  width: 170px;
  height: 48px;
  background-size: 100% 100%;
  color: #fff !important;
  padding: 10px 20px;
  font-weight: 500;
  line-height: 2;
  cursor: pointer;
}

.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../images/home/buthvr.png) no-repeat top center;
  background-size: 100% 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-bounce-to-right:hover,
.hvr-bounce-to-right:focus,
.hvr-bounce-to-right:active {
  color: #fff !important;
}

.hvr-bounce-to-right:hover:before,
.hvr-bounce-to-right:focus:before,
.hvr-bounce-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  color: #fff !important;
}

/* only for DE & RT */
.hvrtemp-bouncetemp-totemp-righttemp {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0); */
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
  background: url(../images/home/but.png) no-repeat top center;
  width: 300px;
  height: 48px;
  background-size: 100% 100%;
  color: #fff !important;
  padding: 10px 20px;
  font-weight: 500;
  line-height: 2;
  cursor: pointer;
}

.hvrtemp-bouncetemp-totemp-righttemp:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../images/home/buthvr.png) no-repeat top center;
  background-size: 100% 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvrtemp-bouncetemp-totemp-righttemp:hover,
.hvrtemp-bouncetemp-totemp-righttemp:focus,
.hvrtemp-bouncetemp-totemp-righttemp:active {
  color: #fff !important;
}

.hvrtemp-bouncetemp-totemp-righttemp:focus:before,
.hvrtemp-bouncetemp-totemp-righttemp:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  color: #fff !important;
}

/* only for DE & RT */

/***********btn15**********/
.why_chs_hvr {

  z-index: 1;
  position: relative;
  display: inline-block;
}

.why_chs_hvr::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: var(--primary);
  border-radius: 5px;
  transition: all 0.3s ease;
}

.why_chs_hvr:hover {
  color: #fff;
}

.why_chs_hvr:hover:after {
  left: 0;
  width: 100%;
}

/*************special_offers*************/

.spec_offer {
  animation: bgChange 0.7s infinite ease-in-out;
  font-size: 22px !important;
  font-weight: 400 !important;
  width: fit-content;
  margin: 0px auto 20px;
  padding: 6px 20px;
  border-radius: 4px;
  color: #fff !important;
}

@keyframes bgChange {
  0% {
    background: #f6ef16d0;
  }

  100% {
    background: #F0371E;
    transform: scale(1.1);
  }
}


/*******************************/

.abt_but button:hover img {
  filter: invert(1) brightness(12);
}

.certifi {
  animation: rotate 20s infinite ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.abt_lft .abt_lft2 {
  background: url(../images/home/abtlft3.png) no-repeat top center;
  background-size: cover;
  height: 180px;
  width: 180px;
  border: 5px solid #fff;
  border-radius: 12px;
  position: absolute;
  bottom: -46px;
  left: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.homefaq_ani {
  background: url(../images/home/hmefaq.png) repeat-x 0 100%;
  animation: graph 130s linear infinite;
  width: 100%;
  height: -webkit-fill-available;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
}

@keyframes graph {
  0% {
    background-position: 2765px 100%, 0 0;
  }

  100% {
    background-position: 0 100%, 0 0;
  }
}

/***************greenButAni****************/
.greenButAni {
  background: #fff !important;
  color: var(--green) !important;
  border: 1px solid #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 40px !important;
  transition: 0.7s !important;
  margin-top: 40px !important;
  animation-name: greenButAni;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
}

.greenButAni:hover {
  background: var(--green) !important;
  color: #fff !important;
  border: 1px solid var(--green) !important;
  box-shadow: inset 160px 0px 0px var(--green) !important;
  transition: 0.7s !important;
}

@keyframes greenButAni {

  0% {}

  100% {
    box-shadow: 0px 0px 20px 6px var(--green);
  }
}




/*****************************/
.playbut {
  position: absolute;
  color: var(--primary);
  width: 60px !important;
  height: 60px !important;
  background: #fff;
  border-radius: 30px;
  padding: 8px 8px;
  left: 44%;
  top: 38%;
  animation: playbut infinite;
  animation-duration: 0.7s;
}

@keyframes playbut {

  0% {}

  100% {
    box-shadow: 0px 0px 24px 14px #3180cf;
  }
}

.reviewG a[href*="elfsight.com/google-reviews-widget"] {
  content-visibility: hidden;
}

/**************************/
.why_choose_bg {
  background: url(../images/home/why_chse-bg.png) no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  padding: 60px 20px;
}



/************card***************/
.card {
  transition: .4s all ease-in;
  border: none;
  margin: 30px auto 0px;
  position: relative;
  display: inline-block;
  height: 100%;
  width: 300px;
}

.card-img-top {
  box-shadow: 0 0 25px rgba(0, 0, 0, .05);
  width: 100%;
}

.carousel-control-next-icon {
  padding: 0 4px 0 0;
}

.product_price {
  float: right;
  color: #6b7270;
  font-size: 20px;
  font-weight: 400;
}

.product-detail {
  position: absolute;
  height: 52px;
  bottom: 0px;
  overflow: hidden;
  padding: 10px 10px !important;
  background: var(--primary);
  transition: all 0.35s;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
}

.card .product-detail h5 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 3px 0;

}

.card .product-detail .subheading {
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.card .product-detail .descrip {
  padding: 0;
  margin: 0;

}

.card:hover .product-detail {
  height: calc(40%);
  padding: 10px;
}

.card:hover .blur {
  height: calc(100%);
  background: #0063d338;
  position: absolute;
  bottom: 0px;
  overflow: hidden;
  transition: all 0.35s;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
}

/***************Hme_program**************/
.program_box {
  width: 330px !important;
  margin: 0px auto !important;
}

.program_box_main {
  margin: 20px 0px 0px 20px;
  position: relative;
}



.program_box_main .prgrm_box_line::after {
  content: '';
  position: absolute;
  background: url(../images/home/prgrm_line2.png) no-repeat top center;
  width: 323px;
  height: 3px;
  /* transform: rotate(90deg); */
  background-size: auto;
  left: -180px;
}

.program_box_main .prgrm_top_con {
  background: var(--green);
  width: fit-content;
  padding: 10px;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 1;
}

.program_box_main .prgrm_txt {
  background: #fff;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.program_box .prgrm_img {
  overflow: hidden;
  height: 210px;
}

.program_box .prgrm_img img {
  transition: 0.7s;
}

.program_box:hover .prgrm_img img {
  transform: scale(1.1);
  transition: 0.7s;
}

.program_slider .slick-prev {
  top: -84px;
  left: 95.5%;
}

.program_slider .slick-next {
  top: -84px;
}

.program_slider .slick-prev:before {
  content: url('../icons8-left-50.png');
  color: #000;
  /* border: 2px solid #000; */
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* background: #fff; */
  font-size: 16px;
  font-family: 'Poppins';
}

.program_slider .slick-next:before {
  content: url('../icons8-right-50.png');
  color: #000;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Poppins';
}


/**************google-review******************/
.google_review_bg {
  background: url(../images/home/google_review-bg.jpg) no-repeat top center;
  background-size: cover;
  padding: 60px 0px 40px;
}

.review_box {
  width: 100% !important;
  max-width: 350px !important;
}

/*************Home_slider**************/

.home_banner .slider_main_bg .slider_con {
  justify-content: space-between;
  min-height: 90vh;
  align-items: center;
  position: relative;
  padding: 80px 0px 0px;
}

.home_banner .slick-next,
.home_banner .slick-prev {
  display: none !important;
}

.home_banner .slick-dots {
  bottom: 0px !important;
}

.home_banner .slide_rht_img .slide_img {
  height: 100%;
  width: 100%;
  max-width: -webkit-fill-available;
}

.home_banner .slick-slide.slick-active .slide_rht_img .slide_img {
  opacity: 0;
  animation-name: rectMovementFromRight;
  animation-duration: 7s;
}

@keyframes rectMovementFromRight {
  0% {
    transform: translateX(calc(150vh)) rotate(0deg) translateY(0);
    opacity: 0.3;

  }

  25% {
    transform: translateX(0vh) rotate(0) translateY(0);
    opacity: 1;

  }

  50% {
    transform: translateX(0vh) rotate(0) translateY(0);
    opacity: 1;

  }

  100% {
    transform: translateX(150vh) rotate(0) translateY(0);
    opacity: 0;

  }
}

.home_banner .hmeslidelft {
  position: absolute;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  min-height: 100vh;
  background-size: 100% 100% !important;
}

.hme_ban_but {
  width: 76%;
  display: flex;
  justify-content: space-between;
}

.home_banner .slick-slide.slick-active .hmeslidelft {
  opacity: 0;
  animation-name: rectMovementFromLeft;
  animation-duration: 7s;
}

@keyframes rectMovementFromLeft {
  0% {
    transform: translateX(calc(-180vh)) rotate(0deg) translateY(0);
    opacity: 0.3;

  }

  25% {
    transform: translateX(0vh) rotate(0) translateY(0);
    opacity: 1;

  }

  50% {
    transform: translateX(0vh) rotate(0) translateY(0);
    opacity: 1;

  }

  100% {
    transform: translateX(-180vh) rotate(0) translateY(0);
    opacity: 0;

  }
}


.home_banner .slick-slide.slick-active .slide_txt_con {
  animation-name: rectMovementtext;
  animation-duration: 6s;
  opacity: 0;
}

@keyframes rectMovementtext {

  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.home_banner .slick-dots li button::before {
  position: absolute;
  content: '―' !important;
  font-size: 60px;
  line-height: 0px;
  top: -15px;
}

.home_banner .slick-dots li {
  width: 70px;
}

/************footer****************/
.foot_icon svg {
  font-size: 20px;
  color: #fff;
  background: var(--darkblue);
  width: 40px;
  height: 40px;
  padding: 8px;
  margin: 0px 10px !important;
  border-radius: 100px;
  position: relative;
}

.footer-bot a {
  color: #fff !important;
  font-size: 14px;
  font-weight: 300;
}

/**************header************/
.logo_box {
  background: #fff;
  padding: 14px 20px 14px 40px;
  position: relative;
  cursor: pointer;
}

.logo_box::before {
  content: "";
  position: absolute;
  right: -32px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 33px solid #fff;
  border-top: 100px solid transparent;
  border-bottom: 0px solid transparent;
}

/* Media Query for Small Mobile Devices */
@media (max-width: 600px) {
  .logo_box {
    padding: 8.4px 12px 8.4px 24px;
    /* 60% of original padding */
    width: 60%;
    /* Decrease width to 60% */
    height: 60%;
    /* Decrease height to 60% */
  }

  .logo_box::before {
    border-left: 19.8px solid #fff;
    /* 60% of original border-left */
    border-top: 60px solid transparent;
    /* 60% of original border-top */
  }
}

/* @media (min-width: 910px) and (max-width: 990px) {
  .logo_box {
    width: 100px !important;
    margin: 0 auto;
  }
} */

.navitems a {
  font-size: 14px !important;
  color: var(--black) !important;
}

.head_top_2_nav a {
  font-size: 12px;
  color: var(--black) !important;
  font-weight: 600;
  letter-spacing: 0px;
}

.head_top_2_nav .nav2 a {
  color: #fff !important;
}

.head_top_2_nav .nav2 svg {
  font-size: 24px !important;
}

.head_top_2_nav nav li {
  width: unset !important;
}

.head_top_2 {
  background: linear-gradient(71deg, #fff 72%, #000 0%);
}

@media (min-width: 1600px) and (max-width: 1880px) {
  .head_top_2 {
    background: linear-gradient(71deg, #fff 85%, #000 0%);
  }
}

.navbar_cal_but {
  padding: 5px 20px 5px 25px;
  line-height: 1.8 !important;
  animation: navbarCallAnimation 1.5s;
  animation-iteration-count: infinite;
}

@keyframes navbarCallAnimation {
  0% {
    background: linear-gradient(80deg, transparent 4%, #000 0%);
  }

  100% {
    background: linear-gradient(80deg, transparent 4%, #26B170 0%);
  }
}

.navbar_cal_but2 {
  padding: 5px 20px 5px 20px;
  line-height: 1.8 !important;
  animation: navbarCallAnimation2 1.5s;
  animation-iteration-count: infinite;
}

@keyframes navbarCallAnimation2 {
  0% {
    background: linear-gradient(90deg, transparent 4%, #000 0%);
  }

  100% {
    background: linear-gradient(90deg, transparent 4%, #26B170 0%);
  }
}

/****************road_map**************/
.road_map .road_con {
  position: relative;
  transition: 0.7s;
}

.road_map .road_con::after {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #CBCBCB;
  top: 0;
  right: 20%;
  font-size: 50px;
  font-weight: 600;
  line-height: 0.6;
  position: absolute;
}

.road_map .road_con:hover::after {
  -webkit-text-fill-color: var(--primary);
  transition: 0.7s;
}

.road_map .road_con1::after {
  content: '01';
}

.road_map .road_con2::after {
  content: '02';
}

.road_map .road_con3::after {
  content: '03';
}

.road_map .road_con4::after {
  content: '04';
}

.road_map .road_con5::after {
  content: '05';
}

.road_map .road_con6::after {
  content: '06';
}

.conex {
  animation: conexAni 3s ease-in-out infinite both;
}

@keyframes conexAni {
  0% {
    transform: scale(1.4);
    transform-origin: center center;
    animation-timing-function: ease-out;
    filter: drop-shadow(0px 8px 3px #151515b5);
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.92);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1.4);
    animation-timing-function: ease-out;
  }
}

.DrivingLessonsFaq_ani {
  background: url(../images/driving-lesson/drivingLessonsfaqBg.png) repeat-y 0 100%;
  animation: drivingLessonFaq 130s linear infinite;
  width: 100%;
  height: -webkit-fill-available;
  position: absolute;
  z-index: -1;
  top: 0;
}

@keyframes drivingLessonFaq {
  0% {
    background-position: 100% 3000px, 0 0;
  }

  100% {
    background-position: 100% 0, 0 0;
  }
}

/******************DrrlEasySteps******************/
.EsyStpBox .esystptxt {
  position: relative;
  transition: 0.7s;
  padding: 0px 30px;
}

.EsyStpBox .esystptxtlft::after {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #CBCBCB;
  top: 10px;
  right: -10%;
  font-size: 50px;
  font-weight: 600;
  line-height: 0.6;
  position: absolute;
}

.EsyStpBox .esystptxtrht::after {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #CBCBCB;
  top: 10px;
  left: -10%;
  font-size: 50px;
  font-weight: 600;
  line-height: 0.6;
  position: absolute;
}

.EsyStpBox .esystptxtlft:hover::after {
  -webkit-text-fill-color: var(--primary);
  transition: 0.7s;
}

.EsyStpBox .esystptxtrht:hover::after {
  -webkit-text-fill-color: var(--primary);
  transition: 0.7s;
}

.EsyStpBox .esystptxtlft1::after {
  content: '01';
}

.EsyStpBox .esystptxtrht2::after {
  content: '02';
}

.EsyStpBox .esystptxtlft3::after {
  content: '03';
}

.EsyStpBox .esystptxtrht4::after {
  content: '04';
}

.EsyStpBox .esystptxtlft5::after {
  content: '05';
}

.EsyStpBox .esystptxtrht6::after {
  content: '06';
}

.DrrlSlider .slick-prev {
  top: 0%;
  left: 50.8%;
  transform: rotate(90deg);
  z-index: 1;
}

.DrrlSlider .slick-next {
  top: 100%;
  left: 50.8%;
  transform: rotate(90deg);
}

.DrrlSlider .slick-next::before {
  color: #000;
  content: '❯';
  font-size: 50px;
  font-weight: bold;
}

.DrrlSlider .slick-prev::before {
  color: #000;
  content: '❮';
  font-size: 50px;
  font-weight: bold;
}

/*******************RoadTestingBanner*****************/

.startTestingBoxMain {
  margin: 0px;
}

.startTestingBox .prgrm_box_line::before {
  content: '';
  position: absolute;
  background: url(../images/home/prgrm_line.png) no-repeat top center;
  background-size: contain;
  width: 220px;
  height: 3px;
  top: -20px;
  left: -20px;
}

.startTestingBox .prgrm_box_line2::before {
  content: '';
  position: absolute;
  background: url(../images/home/prgrm_line.png) no-repeat top center;
  background-size: contain;
  width: 220px;
  height: 3px;
  top: -20px;
}

.startTestingBox .prgrm_box_line::after {
  content: '';
  position: absolute;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUoAAAAECAYAAADoDHatAAAAmElEQVRYhWNkSL78nwEP+H3zESM+eWIAq7ocXjsIAsY/iv9nGzwgW3viFQUGZob7o26gihscGeYYHiBbf8JlBwZmhv0Uu4GBAbcb/rAQMmFg3QDKUf8H2A0QMOoGIt3ARKbTRsEoGFmA4uYCEqCs2TAKBgCMFpSjYBQQA0YLN9oAalZANASjBeUoGAWjYGAAZAhi8AMGBgYA/OU1PR1QEyQAAAAASUVORK5CYII=) no-repeat bottom center;
  width: 200px;
  height: 3px;
  transform: rotate(90deg);
  background-size: auto;
  left: -118px;
  top: 100px;
}

/**********************/

.tabList {
  margin-bottom: unset !important;
  padding: 0px !important;
}

.tabList .react-tabs__tab--selected {
  border: 1px solid #0063D3;
  border-radius: 10px;
  background: linear-gradient(180deg, #DAEBFF, #ECF5FF);
}

.tabList .react-tabs__tab--selected::after {
  display: none !important;
}

/********************/
.scroolbarLocation::-webkit-scrollbar {
  display: none;
}

/******************/
.TimelineLine span {
  background: transparent;
  border: 1px dashed var(--green);
  position: relative;
}

/***********mobileMenu*************/

.menuItemMob {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000c !important;
  display: block;
  padding: 10px 0px 10px;
}

.menuItemMob:hover,
.menuItemMobBut:hover {
  color: var(--primary) !important;
}

.menuItemMobBut {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000c !important;
  margin: 0px !important;
}

.accordion.Mui-expanded {
  margin: 0px !important;
}


/********************/
/* 
.inputs .inputBox , .inputs .MuiFormControl-root{
  width: 100%;
}

.inputBox label{
  padding: 0px 0px 6px;
  color: #000;
  font-size: 15px;
}

.inputs .MuiFormControl-root input{
    height: 24px;
    padding: 10px 10px;
    background: #F6F6F6;
} */

/*************ICONS***********/
.paynowButton .svg {
  font-size: 2rem !important;
}

.paynowButton {
  border-radius: 50% !important;
  min-width: 46px !important;
  height: 46px !important;
  padding: 0px !important;
}



/*********MEDIA*********/

@media only screen and (max-width: 1600px) {

  .head_top_2 {
    background: linear-gradient(71deg, #fff 85%, #000 0%);
  }

}


@media only screen and (max-width: 1400px) {
  .home_banner .hme_ban_but {
    width: 100%;
  }

  .program_slider .slick-prev {
    top: -10%;
    left: 84%;
  }

  .program_slider .slick-next {
    top: -10%;
    right: 8%;
  }

  .google_review_bg {
    padding: 60px 20px 70px;
  }

  .head_top_2_nav nav li a,
  .head_top_2_nav nav li button {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 1200px) {

  .head_top_2_nav nav li a,
  .head_top_2_nav nav li button {
    font-size: 11px;
    text-align: center;
  }

  .logo_box {
    padding: 14px 0px 14px 0px;
  }
}


@media only screen and (max-width: 900px) {

  h1,
  h4,
  subtittle1,
  subtittle2 {
    font-size: 26px !important;
  }

  .slider_main_bg {
    padding: 0px 20px 20px;
  }

  .EasyStepBg .esystpRoad {
    display: none;
  }

  .EasyStepBg .slick-prev {
    display: none !important;
  }

  .EasyStepBg .slick-next {
    display: none !important;
  }

  .EasyStepBg .esystptxt::after {
    display: none !important;
  }

  .EasyStepBg .EsyStpBox {
    grid-template-columns: repeat(1, 1fr);
  }


}

@media only screen and (max-width: 600px) {
  .program_slider .slick-prev {
    top: -44px;
    left: 60%;
  }

  .program_slider .slick-next {
    top: -44px;
    right: 50px;
  }

  .home_banner subtittle1 {
    font-size: 24px !important;
  }

  .home_banner .slider_main_bg .slider_con {
    padding: 0px !important;
  }

  .hme_ban_but {
    justify-content: center;
    flex-direction: column !important;
    gap: 14px;
  }

  .road_map .road_con::after {
    right: 0%;
    font-size: 30px;
  }

  .foot_icon svg {
    font-size: 16px;
    width: 30px;
    height: 30px;
    padding: 6px;
    margin: 0px 4px !important;
  }

}


/* ganesh */


/* Custom scrollbar styling */
.scroll-container {
  height: 500px;
  /* Adjust height as needed */
  overflow-y: scroll;
  /* Ensure scrollbar is always visible */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  border: 1px solid #B0BEC5;
  /* Light gray border */
  border-radius: 8px;
  /* Rounded corners */
  background-color: #FAFAFA;
  /* Light background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  position: relative;
  /* Ensures sticky behavior */
  -webkit-overflow-scrolling: touch;
  /* Improves mobile scrolling behavior */
}

/* Style for WebKit browsers (Chrome, Safari, etc.) */
.scroll-container::-webkit-scrollbar {
  width: 8px;
  /* Visible scrollbar width */
  background-color: #FAFAFA;
  /* Scrollbar background */
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track background color */
}

.scroll-container::-webkit-scrollbar-thumb {
  background: black;
  /* Dark scrollbar thumb */
  border-radius: 8px;
  /* Rounded scrollbar thumb */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #333;
  /* Darker thumb on hover */
}

/* Style for Firefox */
.scroll-container {
  scrollbar-width: thin;
  /* Make scrollbar thinner */
  scrollbar-color: black #f1f1f1;
  /* Dark thumb and light track */
}

@media (max-width: 600px) {
  .scroll-container {
    height: 250px;
    /* Adjust height for xs devices */
    -webkit-overflow-scrolling: touch;
    /* Ensure smooth scrolling on mobile */
  }
}



/* scrollbar */
.react-scrollbar-default {
  width: 35%;
  min-width: 300px;
  max-height: 450px;
}

/*The Content*/
.scroll-me {
  min-width: 750px;
}

/* scrollbar */




/* limited time offer */


/* limited time offer */